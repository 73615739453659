<template>
  <div v-if="$store.state.user != null">
    <Menubar :model="items" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
      <template #start>
        <img :src="require('@/assets/logo2.png')" width="100px" height="50px" />
      </template>
      <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a p-ripple :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else p-ripple :href="item.url" :target="item.target" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
      </template>

      <template #end>
        <div class="flex items-center">
          <Avatar icon="pi pi-user" shape="circle" @click="logout($event)" />
        </div>
      </template>
    </Menubar>
  </div>
  <ConfirmPopup></ConfirmPopup>
  <Toast />

  <router-view />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

import Badge from "primevue/badge";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import ConfirmPopup from "primevue/confirmpopup";
import Toast from "primevue/toast";

export default {
  name: "App",
  components: {
    ConfirmPopup,
    Badge,
    Toast,
    Menubar,
    Button,
    Avatar,
    HelloWorld,
  },
  data: () => ({
    items: [
      {
        label: "الرئيسية",
        icon: "pi pi-home",
        route: "/",
      },
      {
        label: "الاستمارات",
        icon: "pi pi-file-check",
        route: "/application",
      },
      {
        label: "التقارير",
        icon: "pi pi-search",
        route: "/report",
      },
    ],
    chartLabels: null,
    studentsNumber: null,
  }),
  created() {
    let self = this;
    this.$store
      .dispatch("getUserDate")
      .then(() => {
        if (this.$store.state.user) {
          if (this.$store.state.userData.mail == "hussein.sattar@turath.edu.iq" || this.$store.state.userData.mail == "owner.ali@uoturath.edu.iq") {
            self.items = [
              {
                label: "الرئيسية",
                icon: "pi pi-home",
                route: "/",
              },
              {
                label: "الاستمارات",
                icon: "pi pi-file-check",
                route: "/application",
              },
              {
                label: "التقارير",
                icon: "pi pi-search",
                route: "/report",
              },
            ];
            this.$router.push("/").catch(() => {});
          } else {
            self.items = [
              {
                label: "الاستمارات",
                icon: "pi pi-file-check",
                route: "/application",
              },
            ];
            this.$router.push("/application").catch(() => {});
          }
          if (this.$store.state.userData.idEmployee == 1308) {
            self.items = [
              {
                label: "الرئيسية",
                icon: "pi pi-home",
                route: "/",
              },
              {
                label: "التقارير",
                icon: "pi pi-search",
                route: "/report",
              },
            ];
          }
          this.login = true;
          // this.$router.push("/").catch(() => {});
          this.initialization();
        } else {
          this.$router.push("/login").catch(() => {});
          this.login = false;
        }
      })
      .catch(() => {});
  },
  methods: {
    logout(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "هل تريد تسجيل الخروج ؟",
        icon: "pi pi-info-circle",
        rejectProps: {
          label: "الغاء",
          severity: "secondary",
          outlined: true,
        },
        acceptProps: {
          label: "تسجيل خروج",
          severity: "danger",
        },
        accept: () => {
          localStorage.removeItem("employee");
          location.reload();
        },
        reject: () => {},
      });
    },
    initialization() {
      let self = this;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlApplication}/allCategory`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlApplication}/getFormStatistics`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((years, categories, stats) => {
            self.$store.state.years = years.data;
            self.$store.state.categories = categories.data;
            self.$store.state.stats = stats.data;
            self.$store.state.chartLabels = stats.data[0].map((stat) => stat.sectionName);
            self.$store.state.studentsNumber = stats.data[0].map((stat) => stat.numberOfStudents);
            console.log(stats.data);
            self.$store.state.pieData = {
              labels: self.$store.state.chartLabels,
              datasets: [
                {
                  data: self.$store.state.studentsNumber,
                },
              ],
            };
            self.$store.state.barData = {
              labels: self.$store.state.chartLabels,
              datasets: [
                {
                  label: "اعداد الطلبة في الاقسام",
                  data: self.$store.state.studentsNumber,
                  backgroundColor: "#2f4860",
                },
              ],
            };
            self.$store.state.showChart = true;
          })
        )
        .catch((e) => {
          console.log(e);
          // this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "ibm";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(./assets/IBMPlexSansArabic-Medium.ttf) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

*:not(i) {
  font-family: "ibm", sans-serif;
}

#app {
  text-align: center;
  color: #2c3e50;
  direction: rtl;
}
.p-menubar-end {
  margin-right: auto !important;
  margin-left: 0px !important;
}
.teb-list-edit .p-tablist-content .p-tablist-tab-list {
  display: block !important;
}
.teb-list-edit .p-tablist-active-bar {
  background: #1683ef !important;
}
</style>
